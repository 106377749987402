var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.value.showPickupStation &&
      _vm.selectedCountry.isoCode2 === "DE" &&
      _vm.addressType === "2"
        ? [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("de", "delivery_address.salutation")
                  ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.isInRequiredFields(
                                "de",
                                "delivery_address.salutation"
                              ),
                              expression:
                                "isInRequiredFields('de', 'delivery_address.salutation')",
                            },
                          ],
                          staticClass: "input-unit",
                        },
                        [
                          _c("salutation-select", {
                            attrs: {
                              id: "txtSalutation" + _vm._uid,
                              "address-type": _vm.addressType,
                              "address-data": _vm.value,
                              "enabled-address-fields":
                                _vm.optionalAddressFields,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  $event.field,
                                  $event.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "txtSalutation" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressSalutation",
                                      "de",
                                      "delivery_address.salutation"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                (_vm.isInOptionalFields("de", "delivery_address.salutation") &&
                  _vm.value.gender === "company") ||
                (_vm.isInOptionalFields("de", "delivery_address.name1") &&
                  !_vm.isInOptionalFields("de", "delivery_address.salutation"))
                  ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "input-unit",
                          attrs: {
                            "data-model": "name1",
                            "data-validate": "text",
                          },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "company",
                              id: "txtCompany" + _vm._uid,
                              "data-testing": "packing-station-de-company",
                            },
                            domProps: { value: _vm.value.name1 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "name1",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "txtCompany" + _vm._uid } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "Ceres::Template.addressCompany"
                                  )
                                ) + "*"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.isInOptionalFields("de", "delivery_address.title")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "de",
                                  "delivery_address.title"
                                ),
                                expression:
                                  "isInRequiredFields('de', 'delivery_address.title')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "title" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "title",
                                id: "txtTitle" + _vm._uid,
                              },
                              domProps: { value: _vm.value.title },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "title",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtTitle" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressTitle",
                                        "de",
                                        "delivery_address.title"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.areNameFieldsShown("de", "delivery_address")
                    ? [
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.areNameFieldsRequired(
                                    "de",
                                    "delivery_address"
                                  ),
                                  expression:
                                    "areNameFieldsRequired('de', 'delivery_address')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "name2" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "firstName",
                                  id: "txtFirstName" + _vm._uid,
                                  "data-testing":
                                    "packing-station-de-firstname",
                                },
                                domProps: { value: _vm.value.name2 },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "name2",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "txtFirstName" + _vm._uid } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.addressFirstName"
                                      )
                                    )
                                  ),
                                  _vm.areNameFieldsRequired(
                                    "de",
                                    "delivery_address"
                                  )
                                    ? [_vm._v("*")]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.areNameFieldsRequired(
                                    "de",
                                    "delivery_address"
                                  ),
                                  expression:
                                    "areNameFieldsRequired('de', 'delivery_address')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "name3" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "lastName",
                                  id: "txtLastName" + _vm._uid,
                                  "data-testing": "packing-station-de-lastname",
                                },
                                domProps: { value: _vm.value.name3 },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "name3",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "txtLastName" + _vm._uid } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.addressLastName"
                                      )
                                    )
                                  ),
                                  _vm.areNameFieldsRequired(
                                    "de",
                                    "delivery_address"
                                  )
                                    ? [_vm._v("*")]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]
                    : _c("div", { staticClass: "col-12 col-sm-8" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "de",
                                  "delivery_address.contactPerson"
                                ),
                                expression:
                                  "isInRequiredFields('de', 'delivery_address.contactPerson')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "contactPerson" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "lastName",
                                id: "txtContactPerson" + _vm._uid,
                              },
                              domProps: { value: _vm.value.contactPerson },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "contactPerson",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtContactPerson" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressContactPerson",
                                        "de",
                                        "delivery_address.contactPerson"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                  _vm._v(" "),
                  _vm.isInOptionalFields("de", "delivery_address.name4")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "de",
                                  "delivery_address.name4"
                                ),
                                expression:
                                  "isInRequiredFields('de', 'delivery_address.name4')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "name4" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "decorateName",
                                id: "txtAdditionalName" + _vm._uid,
                              },
                              domProps: { value: _vm.value.name4 },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "name4",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: { for: "txtAdditionalName" + _vm._uid },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressAdditionalName",
                                        "de",
                                        "delivery_address.name4"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isInOptionalFields("de", "delivery_address.phoneNumber")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "de",
                                  "delivery_address.phoneNumber"
                                ),
                                expression:
                                  "isInRequiredFields('de', 'delivery_address.phoneNumber')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "telephone" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "telephone",
                                id: "txtTelephone" + _vm._uid,
                              },
                              domProps: { value: _vm.value.telephone },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "telephone",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtTelephone" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressTelephone",
                                        "de",
                                        "delivery_address.phoneNumber"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _vm.isParcelOrOfficeAvailable
              ? _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("input", {
                        attrs: {
                          type: "checkbox",
                          name: "togglePickup",
                          id: "showPickup" + _vm._uid,
                        },
                        domProps: { checked: _vm.value.showPickupStation },
                        on: {
                          change: function ($event) {
                            return _vm.togglePickupStation(
                              $event.target.checked
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "showPickup" + _vm._uid } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "Ceres::Template.addressToPickupStation"
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-sm-8" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input-unit",
                      attrs: { "data-validate": "", "data-model": "address1" },
                    },
                    [
                      _c(
                        "select",
                        {
                          staticClass: "custom-select",
                          attrs: { id: "address1" + _vm._uid },
                          domProps: { value: _vm.value.address1 },
                          on: {
                            change: function ($event) {
                              return _vm.emitInputEvent(
                                "address1",
                                $event.target.value
                              )
                            },
                          },
                        },
                        [
                          _vm.isParcelBoxAvailable
                            ? _c(
                                "option",
                                {
                                  attrs: { value: "PACKSTATION" },
                                  domProps: { selected: _vm.isPickupStation },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.addressPackingStation"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isPostOfficeAvailable
                            ? _c(
                                "option",
                                {
                                  attrs: { value: "POSTFILIALE" },
                                  domProps: { selected: _vm.isPostOffice },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.addressPostOffice"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "'address1' + _uid" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "Ceres::Template.addressPickupLocation"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input-unit",
                      attrs: {
                        "data-validate": "text",
                        "data-model": "address2",
                      },
                    },
                    [
                      _c("input", {
                        attrs: {
                          type: "text",
                          name: "housenumber",
                          autocomplete: "address-line2",
                          id: "txtNumber" + _vm._uid,
                        },
                        domProps: { value: _vm.value.address2 },
                        on: {
                          input: function ($event) {
                            return _vm.emitInputEvent(
                              "address2",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.isPickupStation
                        ? _c(
                            "label",
                            { attrs: { for: "txtNumber" + _vm._uid } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "Ceres::Template.addressPackingStationNumber"
                                  )
                                ) + "*"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isPostOffice
                        ? _c(
                            "label",
                            { attrs: { for: "txtNumber" + _vm._uid } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "Ceres::Template.addressPostOfficeNumber"
                                  )
                                ) + "*"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input-unit",
                      attrs: {
                        "data-validate": "text",
                        "data-model": "postNumber",
                      },
                    },
                    [
                      _c("input", {
                        attrs: {
                          type: "text",
                          name: "postnumber",
                          id: "postnumber" + _vm._uid,
                          "data-testing": "packing-station-de-postnumber",
                        },
                        domProps: { value: _vm.value.postNumber },
                        on: {
                          input: function ($event) {
                            return _vm.emitInputEvent(
                              "postNumber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "postnumber" + _vm._uid } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate("Ceres::Template.addressPostNummer")
                          ) + "*"
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.isInOptionalFields("de", "delivery_address.address4")
                  ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:text",
                              value: _vm.isInRequiredFields(
                                "de",
                                "delivery_address.address4"
                              ),
                              expression:
                                "isInRequiredFields('de', 'delivery_address.address4')",
                              arg: "text",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "address4" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "decorateAddress",
                              id: "decorateAddress1" + _vm._uid,
                            },
                            domProps: { value: _vm.value.address4 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "address4",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "decorateAddress1" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressAdditionalAddress2",
                                      "de",
                                      "delivery_address.address4"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                {
                  staticClass: "input-unit",
                  attrs: {
                    "data-validate": "text",
                    "data-model": "postalCode",
                  },
                },
                [
                  _c("input", {
                    attrs: {
                      type: "text",
                      name: "zip",
                      id: "txtZip" + _vm._uid,
                      "data-testing": "packing-station-de-postalcode",
                    },
                    domProps: { value: _vm.value.postalCode },
                    on: {
                      input: function ($event) {
                        return _vm.emitInputEvent(
                          "postalCode",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "txtZip" + _vm._uid } }, [
                    _vm._v(
                      _vm._s(_vm.$translate("Ceres::Template.addressZip")) + "*"
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                {
                  staticClass: "input-unit",
                  attrs: { "data-validate": "text", "data-model": "town" },
                },
                [
                  _c("input", {
                    attrs: {
                      type: "text",
                      name: "town",
                      id: "txtPlace" + _vm._uid,
                      "data-testing": "packing-station-de-town",
                    },
                    domProps: { value: _vm.value.town },
                    on: {
                      input: function ($event) {
                        return _vm.emitInputEvent("town", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "txtPlace" + _vm._uid } }, [
                    _vm._v(
                      _vm._s(_vm.$translate("Ceres::Template.addressPlace")) +
                        "*"
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12 col-sm-4",
                attrs: { "data-testing": "address-country-select" },
              },
              [
                _c("country-select", {
                  attrs: {
                    "selected-country-id": _vm.value.countryId,
                    "selected-state-id": _vm.value.stateId,
                    "address-type": _vm.addressType,
                    "optional-address-fields": _vm.optionalAddressFields,
                    "required-address-fields": _vm.requiredAddressFields,
                  },
                  on: {
                    "country-changed": function ($event) {
                      return _vm.onSelectedCountryChanged($event)
                    },
                    "state-changed": function ($event) {
                      return _vm.emitInputEvent("stateId", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("custom-address-fields"),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("hr", { staticClass: "mt-0" }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("de", "delivery_address.email")
                  ? _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:mail",
                              value: _vm.isInOptionalFields(
                                "de",
                                "delivery_address.email"
                              ),
                              expression:
                                "isInOptionalFields('de', 'delivery_address.email')",
                              arg: "mail",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "email" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "email",
                              name: "email",
                              id: "email" + _vm._uid,
                              "data-testing": "packing-station-de-email-input",
                            },
                            domProps: { value: _vm.value.email },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "email" + _vm._uid } }, [
                            _vm._v(
                              _vm._s(
                                _vm.transformTranslation(
                                  "Ceres::Template.addressMail",
                                  "de",
                                  "delivery_address.email"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        : _vm.localeToShow == "DE" && _vm.addressType === "1"
        ? [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("de", "billing_address.salutation")
                  ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.isInRequiredFields(
                                "de",
                                "billing_address.salutation"
                              ),
                              expression:
                                "isInRequiredFields('de', 'billing_address.salutation')",
                            },
                          ],
                          staticClass: "input-unit",
                        },
                        [
                          _c("salutation-select", {
                            attrs: {
                              id: "txtSalutation" + _vm._uid,
                              "address-type": _vm.addressType,
                              "address-data": _vm.value,
                              "enabled-address-fields":
                                _vm.optionalAddressFields,
                              "default-salutation": _vm.defaultSalutation,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  $event.field,
                                  $event.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "txtSalutation" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressSalutation",
                                      "de",
                                      "billing_address.salutation"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                (_vm.isInOptionalFields("de", "billing_address.salutation") &&
                  _vm.value.gender === "company") ||
                (_vm.isInOptionalFields("de", "billing_address.name1") &&
                  !_vm.isInOptionalFields("de", "billing_address.salutation"))
                  ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "input-unit",
                          attrs: {
                            "data-validate": "text",
                            "data-model": "name1",
                          },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "company",
                              id: "txtCompany" + _vm._uid,
                              "data-autofocus": "",
                              "data-testing": "billing-address-de-company",
                            },
                            domProps: { value: _vm.value.name1 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "name1",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "txtCompany" + _vm._uid } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "Ceres::Template.addressCompany"
                                  )
                                ) + "*"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("vat-id", {
                      attrs: {
                        "is-required": _vm.isInRequiredFields(
                          "de",
                          "billing_address.vatNumber"
                        ),
                        "selected-country-id": _vm.value.countryId,
                        value: _vm.value.vatNumber || "",
                        "show-input":
                          (_vm.isInOptionalFields(
                            "de",
                            "billing_address.salutation"
                          ) &&
                            _vm.value.gender === "company" &&
                            _vm.isInOptionalFields(
                              "de",
                              "billing_address.vatNumber"
                            )) ||
                          (!_vm.isInOptionalFields(
                            "de",
                            "billing_address.salutation"
                          ) &&
                            _vm.isInOptionalFields(
                              "de",
                              "billing_address.name1"
                            ) &&
                            _vm.isInOptionalFields(
                              "de",
                              "billing_address.vatNumber"
                            )),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.emitInputEvent("vatNumber", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12",
                attrs: { "data-testing": "billing-address-de-name-inputs" },
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _vm.isInOptionalFields("de", "billing_address.title")
                      ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.isInRequiredFields(
                                    "de",
                                    "billing_address.title"
                                  ),
                                  expression:
                                    "isInRequiredFields('de', 'billing_address.title')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "title" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "title",
                                  id: "txtTitle" + _vm._uid,
                                  "data-autofocus": "",
                                  "data-testing": "billing-address-de-title",
                                },
                                domProps: { value: _vm.value.title },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "title",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "txtTitle" + _vm._uid } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.transformTranslation(
                                          "Ceres::Template.addressTitle",
                                          "de",
                                          "billing_address.title"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.areNameFieldsShown("de", "billing_address")
                      ? [
                          _c("div", { staticClass: "col-12 col-sm-4" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate:text",
                                    value: _vm.areNameFieldsRequired(
                                      "de",
                                      "billing_address"
                                    ),
                                    expression:
                                      "areNameFieldsRequired('de', 'billing_address')",
                                    arg: "text",
                                  },
                                ],
                                staticClass: "input-unit",
                                attrs: { "data-model": "name2" },
                              },
                              [
                                _c("input", {
                                  attrs: {
                                    type: "text",
                                    name: "firstName",
                                    id: "txtFirstName" + _vm._uid,
                                    "data-autofocus": "",
                                    "data-testing":
                                      "billing-address-de-firstname",
                                  },
                                  domProps: { value: _vm.value.name2 },
                                  on: {
                                    input: function ($event) {
                                      return _vm.emitInputEvent(
                                        "name2",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { attrs: { for: "txtFirstName" + _vm._uid } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$translate(
                                          "Ceres::Template.addressFirstName"
                                        )
                                      )
                                    ),
                                    _vm.areNameFieldsRequired(
                                      "de",
                                      "billing_address"
                                    )
                                      ? [_vm._v("*")]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-4" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate:text",
                                    value: _vm.areNameFieldsRequired(
                                      "de",
                                      "billing_address"
                                    ),
                                    expression:
                                      "areNameFieldsRequired('de', 'billing_address')",
                                    arg: "text",
                                  },
                                ],
                                staticClass: "input-unit",
                                attrs: { "data-model": "name3" },
                              },
                              [
                                _c("input", {
                                  attrs: {
                                    type: "text",
                                    name: "lastName",
                                    id: "txtLastName" + _vm._uid,
                                    "data-testing":
                                      "billing-address-de-lastname",
                                  },
                                  domProps: { value: _vm.value.name3 },
                                  on: {
                                    input: function ($event) {
                                      return _vm.emitInputEvent(
                                        "name3",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { attrs: { for: "txtLastName" + _vm._uid } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$translate(
                                          "Ceres::Template.addressLastName"
                                        )
                                      )
                                    ),
                                    _vm.areNameFieldsRequired(
                                      "de",
                                      "billing_address"
                                    )
                                      ? [_vm._v("*")]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ]
                      : _c("div", { staticClass: "col-12 col-sm-8" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.isInRequiredFields(
                                    "de",
                                    "billing_address.contactPerson"
                                  ),
                                  expression:
                                    "isInRequiredFields('de', 'billing_address.contactPerson')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "contactPerson" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "lastName",
                                  id: "txtContactPerson" + _vm._uid,
                                },
                                domProps: { value: _vm.value.contactPerson },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "contactPerson",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: { for: "txtContactPerson" + _vm._uid },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.transformTranslation(
                                          "Ceres::Template.addressContactPerson",
                                          "de",
                                          "billing_address.contactPerson"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                    _vm._v(" "),
                    _vm.isInOptionalFields("de", "billing_address.name4")
                      ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.isInRequiredFields(
                                    "de",
                                    "billing_address.name4"
                                  ),
                                  expression:
                                    "isInRequiredFields('de', 'billing_address.name4')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "name4" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "decorateName",
                                  id: "txtAdditionalName" + _vm._uid,
                                },
                                domProps: { value: _vm.value.name4 },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "name4",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "txtAdditionalName" + _vm._uid,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.transformTranslation(
                                          "Ceres::Template.addressAdditionalName",
                                          "de",
                                          "billing_address.name4"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isInOptionalFields("de", "billing_address.birthday")
                      ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:date",
                                  value:
                                    _vm.isInRequiredFields(
                                      "de",
                                      "billing_address.birthday"
                                    ) ||
                                    (!!_vm.value.birthday &&
                                      !!_vm.value.birthday.length),
                                  expression:
                                    "isInRequiredFields('de', 'billing_address.birthday') || !!value.birthday && !!value.birthday.length",
                                  arg: "date",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "birthday" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "date",
                                  min: "1901-12-14",
                                  max: new Date().toISOString().split("T")[0],
                                  name: "birthday",
                                  placeholder: _vm.$translate(
                                    "Ceres::Template.addressBirthdatePlaceholder"
                                  ),
                                  id: "txtBirthdate" + _vm._uid,
                                },
                                domProps: { value: _vm.value.birthday },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "birthday",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "txtBirthdate" + _vm._uid } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.transformTranslation(
                                          "Ceres::Template.addressBirthdate",
                                          "de",
                                          "billing_address.birthday"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isInOptionalFields("de", "billing_address.phoneNumber")
                      ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.isInRequiredFields(
                                    "de",
                                    "billing_address.phoneNumber"
                                  ),
                                  expression:
                                    "isInRequiredFields('de', 'billing_address.phoneNumber')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "telephone" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "telephone",
                                  id: "txtTelephone" + _vm._uid,
                                },
                                domProps: { value: _vm.value.telephone },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "telephone",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "txtTelephone" + _vm._uid } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.transformTranslation(
                                          "Ceres::Template.addressTelephone",
                                          "de",
                                          "billing_address.phoneNumber"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12",
                attrs: { "data-testing": "billing-address-de-street-inputs" },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-sm-8" }, [
                    _c(
                      "div",
                      {
                        staticClass: "input-unit",
                        attrs: {
                          "data-validate": "text",
                          "data-model": "address1",
                        },
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: "text",
                            name: "street",
                            autocomplete: "address-line1",
                            id: "txtStreet" + _vm._uid,
                            "data-testing": "billing-address-de-street",
                          },
                          domProps: { value: _vm.value.address1 },
                          on: {
                            input: function ($event) {
                              return _vm.emitInputEvent(
                                "address1",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "txtStreet" + _vm._uid } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$translate("Ceres::Template.addressStreet")
                              ) + "*"
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "input-unit",
                        attrs: {
                          "data-validate": "text",
                          "data-model": "address2",
                        },
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: "text",
                            name: "housenumber",
                            autocomplete: "address-line2",
                            id: "txtNumber" + _vm._uid,
                            "data-testing": "billing-address-de-house-number",
                          },
                          domProps: { value: _vm.value.address2 },
                          on: {
                            input: function ($event) {
                              return _vm.emitInputEvent(
                                "address2",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "txtNumber" + _vm._uid } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$translate("Ceres::Template.addressNumber")
                              ) + "*"
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("de", "billing_address.address3")
                  ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:text",
                              value: _vm.isInRequiredFields(
                                "de",
                                "billing_address.address3"
                              ),
                              expression:
                                "isInRequiredFields('de', 'billing_address.address3')",
                              arg: "text",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "address3" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "decorateAddress",
                              id: "decorateAddress0" + _vm._uid,
                            },
                            domProps: { value: _vm.value.address3 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "address3",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "decorateAddress0" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressAdditionalAddress1",
                                      "de",
                                      "billing_address.address3"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isInOptionalFields("de", "billing_address.address4")
                  ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:text",
                              value: _vm.isInRequiredFields(
                                "de",
                                "billing_address.address4"
                              ),
                              expression:
                                "isInRequiredFields('de', 'billing_address.address4')",
                              arg: "text",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "address4" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "decorateAddress",
                              id: "decorateAddress1" + _vm._uid,
                            },
                            domProps: { value: _vm.value.address4 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "address4",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "decorateAddress1" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressAdditionalAddress2",
                                      "de",
                                      "billing_address.address4"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                {
                  staticClass: "input-unit",
                  attrs: {
                    "data-validate": "text",
                    "data-model": "postalCode",
                  },
                },
                [
                  _c("input", {
                    attrs: {
                      type: "text",
                      name: "zip",
                      id: "txtZip" + _vm._uid,
                      "data-testing": "billing-address-de-zip",
                    },
                    domProps: { value: _vm.value.postalCode },
                    on: {
                      input: function ($event) {
                        return _vm.emitInputEvent(
                          "postalCode",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "txtZip" + _vm._uid } }, [
                    _vm._v(
                      _vm._s(_vm.$translate("Ceres::Template.addressZip")) + "*"
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                {
                  staticClass: "input-unit",
                  attrs: { "data-validate": "text", "data-model": "town" },
                },
                [
                  _c("input", {
                    attrs: {
                      type: "text",
                      name: "town",
                      id: "txtPlace" + _vm._uid,
                      "data-testing": "billing-address-de-town",
                    },
                    domProps: { value: _vm.value.town },
                    on: {
                      input: function ($event) {
                        return _vm.emitInputEvent("town", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "txtPlace" + _vm._uid } }, [
                    _vm._v(
                      _vm._s(_vm.$translate("Ceres::Template.addressPlace")) +
                        "*"
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12 col-sm-4",
                attrs: { "data-testing": "address-country-select" },
              },
              [
                _c("country-select", {
                  attrs: {
                    "selected-country-id": _vm.value.countryId,
                    "selected-state-id": _vm.value.stateId,
                    "address-type": _vm.addressType,
                    "optional-address-fields": _vm.optionalAddressFields,
                    "required-address-fields": _vm.requiredAddressFields,
                  },
                  on: {
                    "country-changed": function ($event) {
                      return _vm.onSelectedCountryChanged($event)
                    },
                    "state-changed": function ($event) {
                      return _vm.emitInputEvent("stateId", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("custom-address-fields"),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("hr", { staticClass: "mt-0" }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("de", "billing_address.email")
                  ? _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:mail",
                              value: _vm.isInOptionalFields(
                                "de",
                                "billing_address.email"
                              ),
                              expression:
                                "isInOptionalFields('de', 'billing_address.email')",
                              arg: "mail",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "email" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "email",
                              name: "email",
                              id: "email" + _vm._uid,
                              "data-testing": "billing-address-de-email-input",
                            },
                            domProps: { value: _vm.value.email },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "email" + _vm._uid } }, [
                            _vm._v(
                              _vm._s(
                                _vm.transformTranslation(
                                  "Ceres::Template.addressMail",
                                  "de",
                                  "billing_address.email"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        : _vm.localeToShow == "GB" && _vm.addressType === "1"
        ? [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("gb", "billing_address.salutation")
                  ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.isInRequiredFields(
                                "gb",
                                "billing_address.salutation"
                              ),
                              expression:
                                "isInRequiredFields('gb', 'billing_address.salutation')",
                            },
                          ],
                          staticClass: "input-unit",
                        },
                        [
                          _c("salutation-select", {
                            attrs: {
                              id: "txtSalutation" + _vm._uid,
                              "address-type": _vm.addressType,
                              "address-data": _vm.value,
                              "enabled-address-fields":
                                _vm.optionalAddressFields,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  $event.field,
                                  $event.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "txtSalutation" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressSalutation",
                                      "gb",
                                      "billing_address.salutation"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                (_vm.isInOptionalFields("gb", "billing_address.salutation") &&
                  _vm.value.gender === "company") ||
                (_vm.isInOptionalFields("gb", "billing_address.name1") &&
                  !_vm.isInOptionalFields("gb", "billing_address.salutation"))
                  ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "input-unit",
                          attrs: {
                            "data-validate": "text",
                            "data-model": "name1",
                          },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "company",
                              id: "txtCompany" + _vm._uid,
                              "data-autofocus": "",
                            },
                            domProps: { value: _vm.value.name1 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "name1",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "txtCompany" + _vm._uid } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "Ceres::Template.addressCompany"
                                  )
                                ) + "*"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("vat-id", {
                      attrs: {
                        "is-required": _vm.isInRequiredFields(
                          "gb",
                          "billing_address.vatNumber"
                        ),
                        "selected-country-id": _vm.value.countryId,
                        value: _vm.value.vatNumber || "",
                        "show-input":
                          (_vm.isInOptionalFields(
                            "gb",
                            "billing_address.salutation"
                          ) &&
                            _vm.value.gender === "company" &&
                            _vm.isInOptionalFields(
                              "gb",
                              "billing_address.vatNumber"
                            )) ||
                          (!_vm.isInOptionalFields(
                            "gb",
                            "billing_address.salutation"
                          ) &&
                            _vm.isInOptionalFields(
                              "gb",
                              "billing_address.name1"
                            ) &&
                            _vm.isInOptionalFields(
                              "gb",
                              "billing_address.vatNumber"
                            )),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.emitInputEvent("vatNumber", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.isInOptionalFields("gb", "billing_address.title")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "gb",
                                  "billing_address.title"
                                ),
                                expression:
                                  "isInRequiredFields('gb', 'billing_address.title')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "title" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "title",
                                id: "txtTitle" + _vm._uid,
                                "data-autofocus": "",
                              },
                              domProps: { value: _vm.value.title },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "title",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtTitle" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressTitle",
                                        "gb",
                                        "billing_address.title"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.areNameFieldsShown("gb", "billing_address")
                    ? [
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.areNameFieldsRequired(
                                    "gb",
                                    "billing_address"
                                  ),
                                  expression:
                                    "areNameFieldsRequired('gb', 'billing_address')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "name2" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "firstName",
                                  id: "txtFirstName" + _vm._uid,
                                  "data-model": "name2",
                                  "data-autofocus": "",
                                },
                                domProps: { value: _vm.value.name2 },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "name2",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "txtFirstName" + _vm._uid } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.addressFirstName"
                                      )
                                    )
                                  ),
                                  _vm.areNameFieldsRequired(
                                    "gb",
                                    "billing_address"
                                  )
                                    ? [_vm._v("*")]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.areNameFieldsRequired(
                                    "gb",
                                    "billing_address"
                                  ),
                                  expression:
                                    "areNameFieldsRequired('gb', 'billing_address')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "name3" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "lastName",
                                  id: "txtLastName" + _vm._uid,
                                  "data-model": "name3",
                                },
                                domProps: { value: _vm.value.name3 },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "name3",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "txtLastName" + _vm._uid } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.addressLastName"
                                      )
                                    )
                                  ),
                                  _vm.areNameFieldsRequired(
                                    "gb",
                                    "billing_address"
                                  )
                                    ? [_vm._v("*")]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]
                    : _c("div", { staticClass: "col-12 col-sm-8" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "gb",
                                  "billing_address.contactPerson"
                                ),
                                expression:
                                  "isInRequiredFields('gb', 'billing_address.contactPerson')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "contactPerson" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "lastName",
                                id: "txtContactPerson" + _vm._uid,
                              },
                              domProps: { value: _vm.value.contactPerson },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "contactPerson",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtContactPerson" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressContactPerson",
                                        "gb",
                                        "billing_address.contactPerson"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                  _vm._v(" "),
                  _vm.isInOptionalFields("gb", "billing_address.name4")
                    ? _c("div", { staticClass: "col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "gb",
                                  "billing_address.name4"
                                ),
                                expression:
                                  "isInRequiredFields('gb', 'billing_address.name4')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "name4" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "decorateName",
                                id: "txtAdditionalName" + _vm._uid,
                              },
                              domProps: { value: _vm.value.name4 },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "name4",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: { for: "txtAdditionalName" + _vm._uid },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressGBNameAffix",
                                        "gb",
                                        "billing_address.name4"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isInOptionalFields("gb", "billing_address.birthday")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:date",
                                value:
                                  _vm.isInRequiredFields(
                                    "gb",
                                    "billing_address.birthday"
                                  ) ||
                                  (!!_vm.value.birthday &&
                                    !!_vm.value.birthday.length),
                                expression:
                                  "isInRequiredFields('gb', 'billing_address.birthday') || !!value.birthday && !!value.birthday.length",
                                arg: "date",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "birthday" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "date",
                                min: "1901-12-14",
                                max: new Date().toISOString().split("T")[0],
                                name: "birthday",
                                placeholder: _vm.$translate(
                                  "Ceres::Template.addressBirthdatePlaceholder"
                                ),
                                id: "txtBirthdate" + _vm._uid,
                              },
                              domProps: { value: _vm.value.birthday },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "birthday",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtBirthdate" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressBirthdate",
                                        "gb",
                                        "billing_address.birthday"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isInOptionalFields("gb", "billing_address.phoneNumber")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "gb",
                                  "billing_address.phoneNumber"
                                ),
                                expression:
                                  "isInRequiredFields('gb', 'billing_address.phoneNumber')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "telephone" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "telephone",
                                id: "txtTelephone" + _vm._uid,
                              },
                              domProps: { value: _vm.value.telephone },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "telephone",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtTelephone" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressTelephone",
                                        "gb",
                                        "billing_address.phoneNumber"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12",
                attrs: { "data-testing": "invoice-addresses-street-select-gb" },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-sm-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "input-unit",
                        attrs: {
                          "data-validate": "text",
                          "data-model": "address1",
                        },
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: "text",
                            name: "street",
                            autocomplete: "address-line1",
                            id: "txtStreet" + _vm._uid,
                          },
                          domProps: { value: _vm.value.address1 },
                          on: {
                            input: function ($event) {
                              return _vm.emitInputEvent(
                                "address1",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "txtStreet" + _vm._uid } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$translate(
                                  "Ceres::Template.addressENAddressLine1"
                                )
                              ) + "*"
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isInOptionalFields("gb", "billing_address.address2")
                    ? _c("div", { staticClass: "col-12 col-sm-12" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "gb",
                                  "billing_address.address2"
                                ),
                                expression:
                                  "isInRequiredFields('gb', 'billing_address.address2')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "address2" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "housenumber",
                                autocomplete: "address-line2",
                                id: "txtNumber" + _vm._uid,
                              },
                              domProps: { value: _vm.value.address2 },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "address2",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtNumber" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressENAddressLine2",
                                        "gb",
                                        "billing_address.address2"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("gb", "billing_address.address3")
                  ? _c("div", { staticClass: "col-12 col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:text",
                              value: _vm.isInRequiredFields(
                                "gb",
                                "billing_address.address3"
                              ),
                              expression:
                                "isInRequiredFields('gb', 'billing_address.address3')",
                              arg: "text",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "address3" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "buildingName",
                              id: "decorateAddress0" + _vm._uid,
                            },
                            domProps: { value: _vm.value.address3 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "address3",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "decorateAddress0" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressENAddressLine3",
                                      "gb",
                                      "billing_address.address3"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isInOptionalFields("gb", "billing_address.address4")
                  ? _c("div", { staticClass: "col-12 col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:text",
                              value: _vm.isInRequiredFields(
                                "gb",
                                "billing_address.address4"
                              ),
                              expression:
                                "isInRequiredFields('gb', 'billing_address.address4')",
                              arg: "text",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "address4" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "buildingName",
                              id: "decorateAddress0" + _vm._uid,
                            },
                            domProps: { value: _vm.value.address4 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "address4",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "decorateAddress0" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressENAddressLine4",
                                      "gb",
                                      "billing_address.address4"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                {
                  staticClass: "input-unit",
                  attrs: { "data-validate": "text", "data-model": "town" },
                },
                [
                  _c("input", {
                    attrs: {
                      type: "text",
                      name: "town",
                      id: "txtPlace" + _vm._uid,
                    },
                    domProps: { value: _vm.value.town },
                    on: {
                      input: function ($event) {
                        return _vm.emitInputEvent("town", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "txtPlace" + _vm._uid } }, [
                    _vm._v(
                      _vm._s(_vm.$translate("Ceres::Template.addressPlace")) +
                        "*"
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                {
                  staticClass: "input-unit",
                  attrs: {
                    "data-validate": "text",
                    "data-model": "postalCode",
                  },
                },
                [
                  _c("input", {
                    attrs: {
                      type: "text",
                      name: "zip",
                      id: "txtZip" + _vm._uid,
                    },
                    domProps: { value: _vm.value.postalCode },
                    on: {
                      input: function ($event) {
                        return _vm.emitInputEvent(
                          "postalCode",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "txtZip" + _vm._uid } }, [
                    _vm._v(
                      _vm._s(_vm.$translate("Ceres::Template.addressZip")) + "*"
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12 col-sm-4",
                attrs: { "data-testing": "address-country-select" },
              },
              [
                _c("country-select", {
                  attrs: {
                    "selected-country-id": _vm.value.countryId,
                    "selected-state-id": _vm.value.stateId,
                    "address-type": _vm.addressType,
                    "optional-address-fields": _vm.optionalAddressFields,
                    "required-address-fields": _vm.requiredAddressFields,
                  },
                  on: {
                    "country-changed": function ($event) {
                      return _vm.onSelectedCountryChanged($event)
                    },
                    "state-changed": function ($event) {
                      return _vm.emitInputEvent("stateId", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("custom-address-fields"),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("hr", { staticClass: "mt-0" }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("gb", "billing_address.email")
                  ? _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:mail",
                              value: _vm.isInOptionalFields(
                                "gb",
                                "billing_address.email"
                              ),
                              expression:
                                "isInOptionalFields('gb', 'billing_address.email')",
                              arg: "mail",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "email" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "email",
                              name: "email",
                              id: "email" + _vm._uid,
                              "data-testing": "billing-address-gb-email-input",
                            },
                            domProps: { value: _vm.value.email },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "email" + _vm._uid } }, [
                            _vm._v(
                              _vm._s(
                                _vm.transformTranslation(
                                  "Ceres::Template.addressMail",
                                  "gb",
                                  "billing_address.email"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        : _vm.localeToShow == "DE" && _vm.addressType === "2"
        ? [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("de", "delivery_address.salutation")
                  ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.isInRequiredFields(
                                "de",
                                "delivery_address.salutation"
                              ),
                              expression:
                                "isInRequiredFields('de', 'delivery_address.salutation')",
                            },
                          ],
                          staticClass: "input-unit",
                        },
                        [
                          _c("salutation-select", {
                            attrs: {
                              id: "txtSalutation" + _vm._uid,
                              "address-type": _vm.addressType,
                              "address-data": _vm.value,
                              "enabled-address-fields":
                                _vm.optionalAddressFields,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  $event.field,
                                  $event.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "txtSalutation" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressSalutation",
                                      "de",
                                      "delivery_address.salutation"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                (_vm.isInOptionalFields("de", "delivery_address.salutation") &&
                  _vm.value.gender === "company") ||
                (_vm.isInOptionalFields("de", "delivery_address.name1") &&
                  !_vm.isInOptionalFields("de", "delivery_address.salutation"))
                  ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "input-unit",
                          attrs: {
                            "data-validate": "text",
                            "data-model": "name1",
                          },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "company",
                              id: "txtCompany" + _vm._uid,
                              "data-autofocus": "",
                            },
                            domProps: { value: _vm.value.name1 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "name1",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "txtCompany" + _vm._uid } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "Ceres::Template.addressCompany"
                                  )
                                ) + "*"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("vat-id", {
                      attrs: {
                        "is-required": _vm.isInRequiredFields(
                          "de",
                          "delivery_address.vatNumber"
                        ),
                        "selected-country-id": _vm.value.countryId,
                        value: _vm.value.vatNumber || "",
                        "show-input":
                          (_vm.isInOptionalFields(
                            "de",
                            "delivery_address.salutation"
                          ) &&
                            _vm.value.gender === "company" &&
                            _vm.isInOptionalFields(
                              "de",
                              "delivery_address.vatNumber"
                            )) ||
                          (!_vm.isInOptionalFields(
                            "de",
                            "delivery_address.salutation"
                          ) &&
                            _vm.isInOptionalFields(
                              "de",
                              "delivery_address.name1"
                            ) &&
                            _vm.isInOptionalFields(
                              "de",
                              "delivery_address.vatNumber"
                            )),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.emitInputEvent("vatNumber", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.isInOptionalFields("de", "delivery_address.title")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "de",
                                  "delivery_address.title"
                                ),
                                expression:
                                  "isInRequiredFields('de', 'delivery_address.title')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "title" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "title",
                                id: "txtTitle" + _vm._uid,
                                "data-autofocus": "",
                              },
                              domProps: { value: _vm.value.title },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "title",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtTitle" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressTitle",
                                        "de",
                                        "delivery_address.title"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.areNameFieldsShown("de", "delivery_address")
                    ? [
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.areNameFieldsRequired(
                                    "de",
                                    "delivery_address"
                                  ),
                                  expression:
                                    "areNameFieldsRequired('de', 'delivery_address')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "name2" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "firstName",
                                  id: "txtFirstName" + _vm._uid,
                                  "data-autofocus": "",
                                  "data-testing":
                                    "delivery-address-de-firstname",
                                },
                                domProps: { value: _vm.value.name2 },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "name2",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "txtFirstName" + _vm._uid } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.addressFirstName"
                                      )
                                    )
                                  ),
                                  _vm.areNameFieldsRequired(
                                    "de",
                                    "delivery_address"
                                  )
                                    ? [_vm._v("*")]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.areNameFieldsRequired(
                                    "de",
                                    "delivery_address"
                                  ),
                                  expression:
                                    "areNameFieldsRequired('de', 'delivery_address')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "name3" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "lastName",
                                  id: "txtLastName" + _vm._uid,
                                  "data-testing":
                                    "delivery-address-de-lastname",
                                },
                                domProps: { value: _vm.value.name3 },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "name3",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "txtLastName" + _vm._uid } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.addressLastName"
                                      )
                                    )
                                  ),
                                  _vm.areNameFieldsRequired(
                                    "de",
                                    "delivery_address"
                                  )
                                    ? [_vm._v("*")]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]
                    : _c("div", { staticClass: "col-12 col-sm-8" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "de",
                                  "delivery_address.contactPerson"
                                ),
                                expression:
                                  "isInRequiredFields('de', 'delivery_address.contactPerson')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "contactPerson" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "lastName",
                                id: "txtContactPerson" + _vm._uid,
                              },
                              domProps: { value: _vm.value.contactPerson },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "contactPerson",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtContactPerson" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressContactPerson",
                                        "de",
                                        "delivery_address.contactPerson"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                  _vm._v(" "),
                  _vm.isInOptionalFields("de", "delivery_address.name4")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "de",
                                  "delivery_address.name4"
                                ),
                                expression:
                                  "isInRequiredFields('de', 'delivery_address.name4')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "name4" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "decorateName",
                                id: "txtAdditionalName" + _vm._uid,
                              },
                              domProps: { value: _vm.value.name4 },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "name4",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: { for: "txtAdditionalName" + _vm._uid },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressAdditionalName",
                                        "de",
                                        "delivery_address.name4"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isInOptionalFields("de", "delivery_address.phoneNumber")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "de",
                                  "delivery_address.phoneNumber"
                                ),
                                expression:
                                  "isInRequiredFields('de', 'delivery_address.phoneNumber')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "telephone" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "telephone",
                                id: "txtTelephone" + _vm._uid,
                              },
                              domProps: { value: _vm.value.telephone },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "telephone",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtTelephone" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressTelephone",
                                        "de",
                                        "delivery_address.phoneNumber"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _vm.isParcelOrOfficeAvailable
              ? _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("input", {
                        attrs: {
                          type: "checkbox",
                          name: "togglePickup",
                          id: "showPickup" + _vm._uid,
                        },
                        domProps: { checked: _vm.value.showPickupStation },
                        on: {
                          change: function ($event) {
                            return _vm.togglePickupStation(
                              $event.target.checked
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "showPickup" + _vm._uid } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "Ceres::Template.addressToPickupStation"
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-sm-8" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input-unit",
                      attrs: {
                        "data-validate": "text",
                        "data-model": "address1",
                      },
                    },
                    [
                      _c("input", {
                        attrs: {
                          type: "text",
                          name: "street",
                          autocomplete: "address-line1",
                          id: "txtStreet" + _vm._uid,
                          "data-testing": "delivery-address-de-street",
                        },
                        domProps: { value: _vm.value.address1 },
                        on: {
                          input: function ($event) {
                            return _vm.emitInputEvent(
                              "address1",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "txtStreet" + _vm._uid } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate("Ceres::Template.addressStreet")
                          ) + "*"
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input-unit",
                      attrs: {
                        "data-validate": "text",
                        "data-model": "address2",
                      },
                    },
                    [
                      _c("input", {
                        attrs: {
                          type: "text",
                          name: "housenumber",
                          autocomplete: "address-line2",
                          id: "txtNumber" + _vm._uid,
                          "data-testing": "delivery-address-de-housenumber",
                        },
                        domProps: { value: _vm.value.address2 },
                        on: {
                          input: function ($event) {
                            return _vm.emitInputEvent(
                              "address2",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "txtNumber" + _vm._uid } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate("Ceres::Template.addressNumber")
                          ) + "*"
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("de", "delivery_address.address3")
                  ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:text",
                              value: _vm.isInRequiredFields(
                                "de",
                                "delivery_address.address3"
                              ),
                              expression:
                                "isInRequiredFields('de', 'delivery_address.address3')",
                              arg: "text",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "address3" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "decorateAddress",
                              id: "decorateAddress0" + _vm._uid,
                            },
                            domProps: { value: _vm.value.address3 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "address3",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "decorateAddress0" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressAdditionalAddress1",
                                      "de",
                                      "delivery_address.address3"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isInOptionalFields("de", "delivery_address.address4")
                  ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:text",
                              value: _vm.isInRequiredFields(
                                "de",
                                "delivery_address.address4"
                              ),
                              expression:
                                "isInRequiredFields('de', 'delivery_address.address4')",
                              arg: "text",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "address4" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "decorateAddress",
                              id: "decorateAddress1" + _vm._uid,
                            },
                            domProps: { value: _vm.value.address4 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "address4",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "decorateAddress1" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressAdditionalAddress2",
                                      "de",
                                      "delivery_address.address4"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                {
                  staticClass: "input-unit",
                  attrs: {
                    "data-validate": "text",
                    "data-model": "postalCode",
                  },
                },
                [
                  _c("input", {
                    attrs: {
                      type: "text",
                      name: "zip",
                      id: "txtZip" + _vm._uid,
                      "data-testing": "delivery-address-de-zip",
                    },
                    domProps: { value: _vm.value.postalCode },
                    on: {
                      input: function ($event) {
                        return _vm.emitInputEvent(
                          "postalCode",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "txtZip" + _vm._uid } }, [
                    _vm._v(
                      _vm._s(_vm.$translate("Ceres::Template.addressZip")) + "*"
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                {
                  staticClass: "input-unit",
                  attrs: { "data-validate": "text", "data-model": "town" },
                },
                [
                  _c("input", {
                    attrs: {
                      type: "text",
                      name: "town",
                      id: "txtPlace" + _vm._uid,
                      "data-testing": "delivery-address-de-town",
                    },
                    domProps: { value: _vm.value.town },
                    on: {
                      input: function ($event) {
                        return _vm.emitInputEvent("town", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "txtPlace" + _vm._uid } }, [
                    _vm._v(
                      _vm._s(_vm.$translate("Ceres::Template.addressPlace")) +
                        "*"
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12 col-sm-4",
                attrs: { "data-testing": "address-country-select" },
              },
              [
                _c("country-select", {
                  attrs: {
                    "selected-country-id": _vm.value.countryId,
                    "selected-state-id": _vm.value.stateId,
                    "address-type": _vm.addressType,
                    "optional-address-fields": _vm.optionalAddressFields,
                    "required-address-fields": _vm.requiredAddressFields,
                  },
                  on: {
                    "country-changed": function ($event) {
                      return _vm.onSelectedCountryChanged($event)
                    },
                    "state-changed": function ($event) {
                      return _vm.emitInputEvent("stateId", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("custom-address-fields"),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("hr", { staticClass: "mt-0" }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("de", "delivery_address.email")
                  ? _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:mail",
                              value: _vm.isInOptionalFields(
                                "de",
                                "delivery_address.email"
                              ),
                              expression:
                                "isInOptionalFields('de', 'delivery_address.email')",
                              arg: "mail",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "email" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "email",
                              name: "email",
                              id: "email" + _vm._uid,
                              "data-testing": "delivery-address-de-email-input",
                            },
                            domProps: { value: _vm.value.email },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "email" + _vm._uid } }, [
                            _vm._v(
                              _vm._s(
                                _vm.transformTranslation(
                                  "Ceres::Template.addressMail",
                                  "de",
                                  "delivery_address.email"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        : _vm.localeToShow == "GB" && _vm.addressType === "2"
        ? [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("gb", "delivery_address.salutation")
                  ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.isInRequiredFields(
                                "gb",
                                "delivery_address.salutation"
                              ),
                              expression:
                                "isInRequiredFields('gb', 'delivery_address.salutation')",
                            },
                          ],
                          staticClass: "input-unit",
                        },
                        [
                          _c("salutation-select", {
                            attrs: {
                              id: "txtSalutation" + _vm._uid,
                              "address-type": _vm.addressType,
                              "address-data": _vm.value,
                              "enabled-address-fields":
                                _vm.optionalAddressFields,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  $event.field,
                                  $event.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "txtSalutation" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressSalutation",
                                      "gb",
                                      "delivery_address.salutation"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                (_vm.isInOptionalFields("gb", "delivery_address.salutation") &&
                  _vm.value.gender === "company") ||
                (_vm.isInOptionalFields("gb", "delivery_address.name1") &&
                  !_vm.isInOptionalFields("gb", "delivery_address.salutation"))
                  ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "input-unit",
                          attrs: {
                            "data-validate": "text",
                            "data-model": "name1",
                          },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "company",
                              id: "txtCompany" + _vm._uid,
                              "data-autofocus": "",
                            },
                            domProps: { value: _vm.value.name1 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "name1",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "txtCompany" + _vm._uid } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "Ceres::Template.addressCompany"
                                  )
                                ) + "*"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("vat-id", {
                      attrs: {
                        "is-required": _vm.isInRequiredFields(
                          "gb",
                          "delivery_address.vatNumber"
                        ),
                        "selected-country-id": _vm.value.countryId,
                        value: _vm.value.vatNumber || "",
                        "show-input":
                          (_vm.isInOptionalFields(
                            "gb",
                            "delivery_address.salutation"
                          ) &&
                            _vm.value.gender === "company" &&
                            _vm.isInOptionalFields(
                              "gb",
                              "delivery_address.vatNumber"
                            )) ||
                          (!_vm.isInOptionalFields(
                            "gb",
                            "delivery_address.salutation"
                          ) &&
                            _vm.isInOptionalFields(
                              "gb",
                              "delivery_address.name1"
                            ) &&
                            _vm.isInOptionalFields(
                              "gb",
                              "delivery_address.vatNumber"
                            )),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.emitInputEvent("vatNumber", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.isInOptionalFields("gb", "delivery_address.title")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "gb",
                                  "delivery_address.title"
                                ),
                                expression:
                                  "isInRequiredFields('gb', 'delivery_address.title')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "title" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "title",
                                id: "txtTitle" + _vm._uid,
                                "data-autofocus": "",
                              },
                              domProps: { value: _vm.value.title },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "title",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtTitle" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressTitle",
                                        "gb",
                                        "delivery_address.title"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.areNameFieldsShown("gb", "delivery_address")
                    ? [
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.areNameFieldsRequired(
                                    "gb",
                                    "delivery_address"
                                  ),
                                  expression:
                                    "areNameFieldsRequired('gb', 'delivery_address')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "name2" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "firstName",
                                  id: "txtFirstName" + _vm._uid,
                                  "data-autofocus": "",
                                },
                                domProps: { value: _vm.value.name2 },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "name2",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "txtFirstName" + _vm._uid } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.addressFirstName"
                                      )
                                    )
                                  ),
                                  _vm.areNameFieldsRequired(
                                    "gb",
                                    "delivery_address"
                                  )
                                    ? [_vm._v("*")]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate:text",
                                  value: _vm.areNameFieldsRequired(
                                    "gb",
                                    "delivery_address"
                                  ),
                                  expression:
                                    "areNameFieldsRequired('gb', 'delivery_address')",
                                  arg: "text",
                                },
                              ],
                              staticClass: "input-unit",
                              attrs: { "data-model": "name3" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "lastName",
                                  id: "txtLastName" + _vm._uid,
                                },
                                domProps: { value: _vm.value.name3 },
                                on: {
                                  input: function ($event) {
                                    return _vm.emitInputEvent(
                                      "name3",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { attrs: { for: "txtLastName" + _vm._uid } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.addressLastName"
                                      )
                                    )
                                  ),
                                  _vm.areNameFieldsRequired(
                                    "gb",
                                    "delivery_address"
                                  )
                                    ? [_vm._v("*")]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]
                    : _c("div", { staticClass: "col-12 col-sm-8" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "gb",
                                  "delivery_address.contactPerson"
                                ),
                                expression:
                                  "isInRequiredFields('gb', 'delivery_address.contactPerson')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "contactPerson" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "lastName",
                                id: "txtContactPerson" + _vm._uid,
                              },
                              domProps: { value: _vm.value.contactPerson },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "contactPerson",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtContactPerson" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressContactPerson",
                                        "gb",
                                        "delivery_address.contactPerson"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                  _vm._v(" "),
                  _vm.isInOptionalFields("gb", "delivery_address.name4")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "gb",
                                  "delivery_address.name4"
                                ),
                                expression:
                                  "isInRequiredFields('gb', 'delivery_address.name4')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "name4" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "decorateName",
                                id: "txtAdditionalName" + _vm._uid,
                              },
                              domProps: { value: _vm.value.name4 },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "name4",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: { for: "txtAdditionalName" + _vm._uid },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressGBNameAffix",
                                        "gb",
                                        "delivery_address.name4"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isInOptionalFields("gb", "delivery_address.phoneNumber")
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:text",
                                value: _vm.isInRequiredFields(
                                  "gb",
                                  "delivery_address.phoneNumber"
                                ),
                                expression:
                                  "isInRequiredFields('gb', 'delivery_address.phoneNumber')",
                                arg: "text",
                              },
                            ],
                            staticClass: "input-unit",
                            attrs: { "data-model": "telephone" },
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "text",
                                name: "telephone",
                                id: "txtTelephone" + _vm._uid,
                              },
                              domProps: { value: _vm.value.telephone },
                              on: {
                                input: function ($event) {
                                  return _vm.emitInputEvent(
                                    "telephone",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "txtTelephone" + _vm._uid } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.transformTranslation(
                                        "Ceres::Template.addressTelephone",
                                        "gb",
                                        "delivery_address.phoneNumber"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-sm-8" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input-unit",
                      attrs: {
                        "data-validate": "text",
                        "data-model": "address1",
                      },
                    },
                    [
                      _c("input", {
                        attrs: {
                          type: "text",
                          name: "street",
                          autocomplete: "address-line1",
                          id: "txtStreet" + _vm._uid,
                        },
                        domProps: { value: _vm.value.address1 },
                        on: {
                          input: function ($event) {
                            return _vm.emitInputEvent(
                              "address1",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "txtStreet" + _vm._uid } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$translate(
                              "Ceres::Template.addressENAddressLine1"
                            )
                          ) + "*"
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.isInOptionalFields("gb", "delivery_address.address2")
                  ? _c("div", { staticClass: "col-12 col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:text",
                              value: _vm.isInRequiredFields(
                                "gb",
                                "delivery_address.address2"
                              ),
                              expression:
                                "isInRequiredFields('gb', 'delivery_address.address2')",
                              arg: "text",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "address2" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "housenumber",
                              autocomplete: "address-line2",
                              id: "txtNumber" + _vm._uid,
                            },
                            domProps: { value: _vm.value.address2 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "address2",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "txtNumber" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressENAddressLine2",
                                      "gb",
                                      "delivery_address.address2"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("gb", "delivery_address.address3")
                  ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:text",
                              value: _vm.isInRequiredFields(
                                "gb",
                                "delivery_address.address3"
                              ),
                              expression:
                                "isInRequiredFields('gb', 'delivery_address.address3')",
                              arg: "text",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "address3" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "buildingName",
                              id: "decorateAddress0" + _vm._uid,
                            },
                            domProps: { value: _vm.value.address3 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "address3",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "decorateAddress0" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressENAddressLine3",
                                      "gb",
                                      "delivery_address.address3"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isInOptionalFields("gb", "delivery_address.address4")
                  ? _c("div", { staticClass: "col-12 col-sm-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:text",
                              value: _vm.isInRequiredFields(
                                "gb",
                                "delivery_address.address4"
                              ),
                              expression:
                                "isInRequiredFields('gb', 'delivery_address.address4')",
                              arg: "text",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "address4" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "buildingName",
                              id: "decorateAddress1" + _vm._uid,
                            },
                            domProps: { value: _vm.value.address4 },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "address4",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "decorateAddress1" + _vm._uid } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformTranslation(
                                      "Ceres::Template.addressENAddressLine4",
                                      "gb",
                                      "delivery_address.address4"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                {
                  staticClass: "input-unit",
                  attrs: { "data-validate": "text", "data-model": "town" },
                },
                [
                  _c("input", {
                    attrs: {
                      type: "text",
                      name: "town",
                      id: "txtPlace" + _vm._uid,
                    },
                    domProps: { value: _vm.value.town },
                    on: {
                      input: function ($event) {
                        return _vm.emitInputEvent("town", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "txtPlace" + _vm._uid } }, [
                    _vm._v(
                      _vm._s(_vm.$translate("Ceres::Template.addressPlace")) +
                        "*"
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                {
                  staticClass: "input-unit",
                  attrs: {
                    "data-validate": "text",
                    "data-model": "postalCode",
                  },
                },
                [
                  _c("input", {
                    attrs: {
                      type: "text",
                      name: "zip",
                      id: "txtZip" + _vm._uid,
                    },
                    domProps: { value: _vm.value.postalCode },
                    on: {
                      input: function ($event) {
                        return _vm.emitInputEvent(
                          "postalCode",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "txtZip" + _vm._uid } }, [
                    _vm._v(
                      _vm._s(_vm.$translate("Ceres::Template.addressZip")) + "*"
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12 col-sm-4",
                attrs: { "data-testing": "address-country-select" },
              },
              [
                _c("country-select", {
                  attrs: {
                    "selected-country-id": _vm.value.countryId,
                    "selected-state-id": _vm.value.stateId,
                    "address-type": _vm.addressType,
                    "optional-address-fields": _vm.optionalAddressFields,
                    "required-address-fields": _vm.requiredAddressFields,
                  },
                  on: {
                    "country-changed": function ($event) {
                      return _vm.onSelectedCountryChanged($event)
                    },
                    "state-changed": function ($event) {
                      return _vm.emitInputEvent("stateId", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("custom-address-fields"),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("hr", { staticClass: "mt-0" }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.isInOptionalFields("gb", "delivery_address.email")
                  ? _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate:mail",
                              value: _vm.isInOptionalFields(
                                "gb",
                                "delivery_address.email"
                              ),
                              expression:
                                "isInOptionalFields('gb', 'delivery_address.email')",
                              arg: "mail",
                            },
                          ],
                          staticClass: "input-unit",
                          attrs: { "data-model": "email" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "email",
                              name: "email",
                              id: "email" + _vm._uid,
                              "data-testing": "delivery-address-gb-email-input",
                            },
                            domProps: { value: _vm.value.email },
                            on: {
                              input: function ($event) {
                                return _vm.emitInputEvent(
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "email" + _vm._uid } }, [
                            _vm._v(
                              _vm._s(
                                _vm.transformTranslation(
                                  "Ceres::Template.addressMail",
                                  "gb",
                                  "delivery_address.email"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }